.Social {
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 64px;
    right: 1vw;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.Social .main {
    padding: 10px 0;
    background-color: rgba(243, 243, 243, 0.577);
    border-radius: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
  }

.Social .icon {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: background-color .5s;
    cursor: pointer;
}

.Social .icon:hover {
    background-color: rgb(255, 255, 255);
}

.Social img {
    height: 32px;
}