.Slider .arrow {
    color: black;
    text-align: center;
    width: 3vw;
    display: inline-block;
    cursor: pointer;
    transition: opacity .5s;
}

.Slider .main {
    display: inline-block;
    margin: auto 2vw;
}

.Slider .arrow:hover{
    opacity: 0.6;
}