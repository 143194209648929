@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.Contact {
    height: 100%;
    width: 100%;
}

.Contact .form {
    position: relative;
    z-index: 1;
    width: 35vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 12.5vw;
}
.Contact .img-bg {
    width: 90%;
    opacity: 0.05;
    position: absolute;
    left: 5%;
    z-index: -10;
}

.Contact .contact-form {
    width: 100%;
    text-align: center;
    margin-bottom: 1vw;
    font-family: 'Montserrat';
}
  
.Contact .contact-form .title {
    font-size: 5vw;
    font-weight: 600;
    color: #344c80;
    margin-bottom: 1vw;
}

.Contact .contact-form .enter-box input {
    font-family: 'Montserrat';
    width: 100%;
    height: 3vw;
    font-size: 1.3vw;
    margin-bottom: 2vw;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.082);
    outline: none;
    border: none;
    border-bottom: 2px #344c80 solid;
    padding: 24px 1vw;
    transition: border-bottom .5s;
}

.Contact .contact-form .enter-box input:active {
    border-bottom: 2px #6e8bca solid;
}

.Contact .contact-form .enter-box input:focus {
    border-bottom: 2px #6e8bca solid;
}

.Contact .contact-form textarea:active {
    border-bottom: 2px #6e8bca solid;
}

.Contact .contact-form textarea:focus {
    border-bottom: 2px #6e8bca solid;
}

.Contact .contact-form textarea {
    width: 100%;
    font-size: 1.3vw;
    border: none;
    background-color: rgba(255, 255, 255, 0.082);
    outline: none;
    border-bottom: 2px #344c80 solid;
    margin-bottom: 1vw;
    padding: 12px 1vw;
    transition: border-bottom .5s;
    font-family: 'Montserrat';
    resize: none;
}

.Contact .contact-form .submit {
    color: white;
    font-size: 1.5vw;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color .3s;
    background-color: #344c80;
    border: none;
    border-radius: 32px;
    font-family: 'Montserrat';
}

.Contact .contact-form .submit:hover {
    background-color: #5d7ab8;
}

.Contact .contact-form .submit:active {
    color: #344c80;
}

.Contact .text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 30vw;
    right: 12.5vw;
    height: 100%;
    color: #79252F;
    font-weight: 600;
    font-size: 1.75vw;
}