.Reviews {
    height: 100%;
}

.Reviews .title {
    color: #79252F;
    font-weight: 600;
    font-size: 6vw;
    display: inline-block;
    margin-top: 10%;
}

.Reviews .img-collab {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -10;
}

.Reviews .bg-main {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -9;
}

.Reviews .bg-main .gradient {
    width: 100%;
    height: 70%;
    display: block;
    background: -moz-linear-gradient(top,  rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(00%,rgba(255, 255, 255, 0)), color-stop(100%,rgb(255, 255, 255))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255, 255, 255, 0.2) 0%,rgb(255, 255, 255) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255, 255, 255, 0.2) 0%,rgb(255, 255, 255) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255, 255, 255, 0.2) 0%,rgb(255, 255, 255) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255, 255, 255, 0.3) 0%,rgb(255, 255, 255) 100%); /* W3C */
}

.Reviews .bg-main .white {
    height: 30%;
    width: 100%;
    background-color: white;
}

/* TESTIMONIALS */

.Reviews .carousel-wrapper {
  width: 75vw;
  margin-left: 12.5vw;
  color: #454545 !important;
}
  
.slider-wrapper {
  width: 100vw !important;
  margin-left: -12.5vw !important;
}

.carousel .slide {
  background: none !important;
}

.testimonial {
  width: 100%;
  padding: 0 15%;
  margin: auto;
}

.testimonial .text {
  font-size: 2vw;
  font-weight: 600;
  text-align: left;
  font-style: italic;
}

.testimonial .author {
  font-size: 2vw;
  text-align: left;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}

.control-arrow {
  background: none !important;
}

.control-next.control-arrow {
  margin-right: -10px;
}
.control-prev.control-arrow {
  margin-left: -10px;
}

.control-arrow:before {
  border-top: 24px solid transparent !important;
  border-bottom: 24px solid transparent !important;
}

.control-next.control-arrow:before {
  border-left: 10px solid black !important;
}

.control-prev.control-arrow:before {
  border-right: 10px solid black !important;
}

@media (max-width: 1200px) {
  .carousel-wrapper {
    width: 90vw;
    margin-left: 5vw;
  }
  .slider-wrapper {
    width: 100vw !important;
    margin-left: -5vw !important;
  }
}

.Reviews .testimonials {
  text-align: center;
  margin-top: 10vh;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reviews .represented img {
  height: 8vw;
}

.Reviews .represented .clearwave img {
  padding: 10px 20px;
}

.Reviews p.represented {
  font-size: 2.8vh;
  font-weight: 500;
  margin: 5vh 20px 0 20px
}