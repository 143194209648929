.mobileMusic .title {
    color: white;
    font-weight: 600;
    padding: 0 3vw;
    font-size: 3vh;
    display: block;
}

.mobileMusic .Slider {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

.iframeContainer {
    width: auto;
    height: auto;
    overflow: auto;
}