
.Home {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: -2;
}

.Home .home-left {
    width: 38%;
    height: 100%;
    background-image: url(../../assets/home-imgs/hugh_with_gtr.jpg);
    background-repeat: no-repeat;
    background-position: -26vw 0vw;
    background-size: max(64vw, 64vh) max(64vw, 64vh);
    position: relative;
    display: inline-block;
    z-index: 1;
}

.Home .home-right {
    width: 62%;
    height: 100%;
    position: relative;
    z-index: -1;
    float: right;
}

.Home .home-right:before {
    content: "";
    background-image: url(../../assets/home-imgs/road.jpg);
    background-repeat: no-repeat;
    background-position: -12.5vw 0;
    background-size: 85vw;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: .54;
}

.Home .home-right .logo {
    width: 30vw;
    position: relative;
    margin: auto;
    margin-top: -5vh;
    cursor: pointer;
}

.Home .home-right .navbar {
    width: 100%;
    position: relative;
    margin-top: -5vh;
    cursor: pointer;
}

.Home .navbar .option {
    margin: 0;
    padding: 0 1vw;
    font-size: 2vw;
    display: inline-block;
    color: #707070;
    font-weight: 600;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: color .3s;
}

.Home .navbar .option:hover {
    color: rgb(60, 60, 60);
}

.Home .home-right .about {
    color: #772D2D;
    font-weight: 600;
    font-size: 2.2vw;
    padding: 0 5vw;
    margin-top: 5vh;
}

.Home .home-right .soundbetter {
    cursor: pointer;
    position: relative;
    top: 0;
}

.Home .home-right .latest-song {
    width: 12%;
    margin-left: 10vh;
    font-size: 1vw;
    font-weight: 600;
    display: inline-block;
    position: relative
}

.Home .home-right .latest-song a {
    text-decoration: none !important;
    color: #454545;
}

.Home .home-right .latest-song img {
    width: 100%;
    cursor: pointer;
    border-radius: 10%;
    border: white 3px solid;
}

