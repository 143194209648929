@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.mobileContact {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileContact .form {
    position: relative;
    z-index: 1;
    width: 80vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mobileContact .img-bg {
    width: 90%;
    opacity: 0.05;
    position: absolute;
    left: 5%;
    z-index: -10;
}

.mobileContact .contact-form {
    width: 100%;
    text-align: center;
    margin-bottom: 1vw;
    font-family: 'Montserrat';
}
  
.mobileContact .contact-form .title {
    font-size: 10vw;
    font-weight: 600;
    color: #344c80;
    margin-bottom: 10vh;
}

.mobileContact .contact-form .enter-box input {
    font-family: 'Montserrat';
    width: 100%;
    height: 3vw;
    font-size: 4.5vw;
    margin-bottom: 2vw;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.082);
    outline: none;
    border: none;
    border-bottom: 2px #344c80 solid;
    padding: 24px 1vw;
    transition: border-bottom .5s;
}

.mobileContact .contact-form .enter-box input:active {
    border-bottom: 2px #6e8bca solid;
}

.mobileContact .contact-form .enter-box input:focus {
    border-bottom: 2px #6e8bca solid;
}

.mobileContact .contact-form textarea:active {
    border-bottom: 2px #6e8bca solid;
}

.mobileContact .contact-form textarea:focus {
    border-bottom: 2px #6e8bca solid;
}

.mobileContact .contact-form textarea {
    width: 100%;
    font-size: 4.5vw;
    border: none;
    background-color: rgba(255, 255, 255, 0.082);
    outline: none;
    border-bottom: 2px #344c80 solid;
    margin-bottom: 1vw;
    padding: 12px 1vw;
    transition: border-bottom .5s;
    font-family: 'Montserrat';
    resize: none;
}

.mobileContact .contact-form .submit {
    color: white;
    font-size: 5vw;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color .3s;
    background-color: #344c80;
    border: none;
    border-radius: 32px;
    font-family: 'Montserrat';
    margin-top: 5vh;
    width: 75vw;
}

.mobileContact .contact-form .submit:hover {
    background-color: #5d7ab8;
}

.mobileContact .contact-form .submit:active {
    color: #344c80;
}

.ContactAbout .contacttext {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    color: #79252F;
    font-weight: 600;
    font-size: 3vh;
}

.ContactAbout {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none !important;
}

.ContactAbout:before {
    content: "";
    background-image: url(../../assets/home-imgs/hugh_headshot.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(.9 * max(100vw, 80vh));
    position: absolute;
    left: 0;
    top: 0;
    right: 0vw;
    bottom: 0;
    z-index: 0;
    opacity: .3;
}