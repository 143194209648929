.mobileHome {
    width: 100%;
    height: 100%;
}

.mobileHome:before {
    content: "";
    background-image: url(../../assets/home-imgs/road.jpg);
    background-repeat: no-repeat;
    background-position: 51% 0;
    background-size: calc(1.5 * min(max(650px, 100vh), 1200px));
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: .54;
}

.mobileHome .logo {
    width: 45vh;
    margin-top: 10vh;
}

.mobileHome .about {
    color: #772D2D;
    font-weight: 600;
    font-size: 2.8vh;
    padding: 0 5vw;
}

.mobileHome .soundbetter {
    width: 20vh;
    cursor: pointer;
}

.mobileHome .latest-song {
    width: 15vh;
    margin-left: 4vh;
    font-size: 2vh;
    font-weight: 600;
    display: inline-block;
    color: #454545;
}

.mobileHome .latest-song img {
    width: 90%;
    cursor: pointer;
    border: white solid 3px;
    border-radius: 10%;
    position: relative;
    top: 0;
}

.mobileHome .latest-song a {
    text-decoration: none;
    color: #454545;
}

.mobileHome .links {
    display: flex;
    justify-content: center;
    align-items: top;
    margin-top: 7vh;
}


