.mobileReviews {
    height: 100%;
    overflow: hidden;
}

.mobileReviews .title {
    color: #79252F;
    font-weight: 600;
    font-size: 18vw;
    display: inline-block;
    margin-top: 15%;
}

.mobileReviews .img-collab {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -10;
}

.mobileReviews .bg-main {
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    z-index: -8;
    display: inline-block;
    overflow: hidden;
}

.mobileReviews .bg-main .gradient {
    position: absolute;
    width: 100vw;
    height: 30vh;
    border: none;
    background: -moz-linear-gradient(top,  rgba(255, 255, 255, 0.3) 0%, rgb(255, 255, 255) 90%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(00%,rgba(255, 255, 255, 0.3)), color-stop(90%,rgb(255, 255, 255))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255, 255, 255, 0.3) 0%,rgb(255, 255, 255) 90%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255, 255, 255, 0.3) 0%,rgb(255, 255, 255) 90%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255, 255, 255, 0.3) 0%,rgb(255, 255, 255) 90%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255, 255, 255, 0.3) 0%,rgb(255, 255, 255) 90%); /* W3C */
}

.mobileReviews .bg-main .white {
    position: absolute;
    height: 71vh;
    top: 29vh;
    width: 100%;
    background-color: white;
}

/* TESTIMONIALS */

.mobileReviews .carousel-wrapper {
  width: 75vw;
  margin-left: 12.5vw;
  color: #454545 !important;
}
  
.mobileReviews .slider-wrapper {
  width: 100vw !important;
  margin-left: -12.5vw !important;
}

.mobileReviews .carousel .slide {
  background: none !important;
}

.mobileReviews .testimonial {
  width: 100%;
  padding: 0 10%;
  margin: auto;
}

.mobileReviews .testimonial .text {
  font-size: 5vw;
  font-weight: 600;
  text-align: left;
  font-style: italic;
}

.mobileReviews .testimonial .author {
  font-size: 5vw;
  text-align: left;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}

.mobileReviews .control-arrow {
  background: none !important;
}

.mobileReviews .control-next.control-arrow {
  margin-right: -10px;
}
.mobileReviews .control-prev.control-arrow {
  margin-left: -10px;
}

.mobileReviews .control-arrow:before {
  border-top: 24px solid transparent !important;
  border-bottom: 24px solid transparent !important;
}

.mobileReviews .control-next.control-arrow:before {
  border-left: 10px solid black !important;
}

.mobileReviews .control-prev.control-arrow:before {
  border-right: 10px solid black !important;
}

@media (max-width: 1200px) {
  .carousel-wrapper {
    width: 90vw;
    margin-left: 5vw;
  }
  .slider-wrapper {
    width: 100vw !important;
    margin-left: -5vw !important;
  }
}

.mobileReviews .testimonials {
  text-align: center;
  top: 10%;
}

.mobileReviews .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileReviews .songwritingBlog img {
  height: 16vh;
}

.mobileReviews .songwritingBlog {
  margin: auto;
  margin-top: calc(45vh - 50vw);
  font-size: 2.6vh;
  font-weight: 500;
}